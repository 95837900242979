/* Logo colour #53F4DB */

.App {
  text-align: center;
  background-color: #282c34;
}
/* Text */
p h1 h2 h3 h4 {
  z-index: 100;
  color: aliceblue;
}

h4 {
  color: aliceblue;
}

/* a {
  color: #53F4DB
} */
.inline-link {
  color: aliceblue;
}
.inline-link:hover {
  color: #53f4db;
}

p {
  color: aliceblue;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Typewriter */

h2 {
  color: aliceblue;
  font-size: Xxx-large;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid aliceblue; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  color: aliceblue;
  z-index: 100;

  font-size: xxx-large;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: aliceblue;
  }
}

.header-area {
  width: 100%;
  padding-top: 12.5em;
  /* height: 25em; */
  text-align: center;
  vertical-align: middle;
  margin: auto;
  display: flex;
  align-items: center; /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
}

.animation-delay h1 {
  animation-delay: 3s;
}

.fade-in h2 {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 10s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 10s forwards; /* Firefox < 16 */
  -ms-animation: fadein 10s forwards; /* Internet Explorer */
  -o-animation: fadein 10s forwards; /* Opera < 12.1 */
  animation: fadein 10s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image-full {
  width: 100%;
  /* filter: grayscale(100%); */
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.image-absolute {
  position: absolute;
  left: 0;
  top: 10;
}

.padding {
  z-index: 100;
  padding-top: 2em;
}

.logo-image {
  height: 2.5em;
}

.no-underline {
  color: white;
  text-decoration: none;
}

.no-underline:hover {
  color: #53f4db;
  text-decoration: underline;
}

.no-underline:active {
  color: #53f4db;
  text-decoration: underline;
}

.header-area {
  padding-top: calc(100vw * 0.3 - 2em);
}
.padding-about {
  padding-top: calc(100vw * 0.3 - 2em);
}

/* MEDIA QUERIES */
@media screen and (min-width: 320px) {
  .typewriter h1 {
    font-size: xx-large;
  }
}

@media screen and (min-width: 600px) {
  .typewriter h1 {
    font-size: xxx-large;
  }
}

.icon {
  height: 2em;
}

.icon-area {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.flex-item-left {
  flex: 50%;
  align-items: center;
  justify-content: center;
}

.flex-item-right {
  flex: 50%;
  align-items: center;
  justify-content: center;
}

.img-wrap {
  width: 50%; /*or whatever you choose*/
  margin: auto;
  padding-bottom: 2em;
}
@media (max-width: 800px) {
  .img-wrap {
    padding-right: 5em;
  }
}

.img-wrap img {
  display: block;
  width: 100%;
  max-width: 500px; /*actual image width*/
  height: auto; /* maintain aspect ratio*/
  margin: auto; /*optional centering of image*/
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}

.small-text {
  font-size: small;
}

.flexible-container {
  gap: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2em;
}

.footer {
  background-color: #121212;
}
